* {
  font-family: 'Noto Sans', sans-serif;
}

a:hover{
  opacity: 0.8; 
}

body {
    background-color: #ef476f;
}

h1, h2, h3, h4 {
  font-family: 'Neue Haas Grotesk Display Pro', sans-serif;
}

.App {
  background-color: #ef476f;
  color: white;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
